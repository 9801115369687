export default {
  texts: [
    // news text
    {
      text_ru: 'Покупайте в рассрочку, оплачивайте вовремя и станьте обладателем автомобиля Spark! Каждый месяц мы разыгрываем холодильники, телевизоры, стиральные машины и множество других призов.\n' +
        '\n' +
        'Мы уже провели два грандиозных розыгрыша в ТРЦ Riviera и магазине бытовой техники и электроники MEDIAPARK Bozori, а теперь ждем новых победителей на нашем следующем розыгрыше, который пройдет 26 февраля. \n' +
        '\n' +
        'Для того, чтобы принять участие в  акции вам досточно зарегистрироваться в приложении Uzum Nasiya, оформить покупку и своевременно вносить оплату.\n' +
        '\n' +
        'Если вы пользуетесь Uzum Nasiya и оплачиваете рассрочку вовремя, вы уже участник розыгрыша!\n' +
        '\n' +
        'Финальный розыгрыш главных призов - трёх автомобилей Spark, начнется в июне 2022 года! Каждые два месяца мы будем дарить по одному автомобилю.',
      text_uz: 'Uzum Nasiya tizimidan foydalaning, muddatli to\'lovni o\'z vaqtida to\'lang va Spark avtomobilining egasiga aylaning! Har oy bizning aksiyamizda muzlatgichlar, televizorlar, kir yuvish mashinalari va boshqa qimmatbaho sovg\'alar o\'ynaladi. \n' +
        '\n' +
        'Biz Riviera savdo markazida va MEDIAPARK Bozori maishiy texnika va elektronika do‘konida iikita ajoyib sovrin o‘yinlarni o\'tkazdik. Endi esa 26-fevralda bo\'lib o\'tadigan keyingi aksiyamizda yangdi go\'liblarni kutib qolamiz. \n' +
        '\n' +
        'Aksiyada ishtirok etish uchun Uzum Nasiya ilovasi orqali ro’yxatdan o’ting, istagan narsangizni xarid qiling va muddatli to‘lovni o‘z vaqtida to‘lang. Hammasi juda oson. \n' +
        '\n' +
        'Agarda siz Uzum Nasiya tizimidan foydalanib to\'lovlarni o\'z vaqtida amalaga oshirsangiz unda siz avtomatik ravishda aksiyaning ishtirokchisiga aylanasiz.\n' +
        '\n' +
        'Eslatib o\'tamizki, 2022 yil iyun oyidan boshlab har ikki oyda bittadan Chevrolet Spark avtomobillari o‘ynaladi. ',
    },

    {
      text_ru: 'Компания Uzum Nasiya дала старт грандиозным розыгрышам и акциям, которые будут проходить каждый месяц, а главные призы - три автомобиля Chevrolet Spark. 26 ноября в торгово-развлекательном центре RIviera прошел первый розыгрыш призов. Счастливчики стали обладателями новых холодильников, телевизоров, стиральных машин и других ценных подарков.\n' +
        '\n' +
        'Хотите участвовать в следующих наших акциях? Зарегистрируйтесь в приложении Uzum Nasiya, оформите покупку и своевременно вносите оплату. Всё очень просто!\n' +
        '\n' +
        'Напомним, что данная акция будет проходить каждый месяц, а о датах розыгрыша мы будем объявлять заранее в наших социальных сетях. Также с июня 2022 года раз в два месяца будут разыгрываться по одному автомобилю Chevrolet Spark, и каждый может выиграть, воспользовавшись услугами Uzum Nasiya.',
      text_uz: 'Uzum Nasiya har oyda boʻlib oʻtadigan ajoyib oʻyinlar va aksiyalarni oʻtkazdi va asosiy sovrinlar uchta Chevrolet Spark avtomobili. 26-noyabr kuni “RIviera” savdo-ko‘ngilochar markazida birinchi sovrinlar o‘yini bo‘lib o‘tdi. Baxtlilarga yangi muzlatgich, televizor, kir yuvish mashinasi va boshqa qimmatbaho sovg‘alar topshirildi.',
    },
    {
      text_ru: 'Компания Uzum Nasiya постоянно проводит грандиозные розыгрыши и акции. Сейчас проходит акция, где клиенты, оплачивающие товары в рассрочку своевременно, каждый месяц имеют шанс получить ценные призы, в числе которых три автомобиля Chevrolet Spark.\n' +
        '\n' +
        'Оплачивая товары, приобретенные в рассрочку, в положенный срок, вы автоматически становитесь частью ежемесячной акции. 26 декабря в магазине бытовой техники и электроники MEDIAPARK Bozori прошел первый розыгрыш призов. Счастливчики стали обладателями новых холодильников, телевизоров, стиральных машин и других подарков.\n' +
        '\n' +
        'Напомним, что данная акция будет проходить каждый месяц, а о датах розыгрыша мы будем объявлять заранее в наших социальных сетях. Также с июня 2022 года раз в два месяца будут разыгрываться по одному автомобилю Chevrolet Spark, и каждый может выиграть, воспользовавшись услугами Uzum Nasiya.',
      text_uz: 'Eslatib oʻtamiz, ushbu aksiya har oy boʻlib oʻtadi va biz ijtimoiy tarmoqlarimizda oʻyin oʻtkazilish sanalarini oldindan eʼlon qilamiz. Shuningdek, 2022-yil iyun oyidan boshlab har ikki oyda bittadan Chevrolet Spark avtomobili oʻynaladi va har kim oʻynashi mumkin Uzum Nasiya xizmatlaridan foydalangan holda yutib oling. ',
    },
    // Qaratash MediaPark 22.02.2022
    {
      text_uz: 'Uzum Nasiyadan yana bir ajoyib aksiya!' + '<br>' + 'Bu galgi aksiya 26-fevral kuni Qoratosh “MEDIAPARK Bozori” maishiy texnika va elektronika do‘konida bo‘lib o‘tdi. O‘yin ishtirokchilari muzlatgich, televizor, kir yuvish mashinasi va boshqa ko‘plab qimmatbaho sovg‘alar sohibiga aylanishdi.\n' +
        '\n' + 'Oylik aksiyamizda har kim yutib olishi mumkin, asosiy sovrinlar esa uchta Chevrolet Spark avtomobili bo‘lib, ular iyundan noyabrgacha har ikki oyda o‘ynaladi. O‘yinda ishtirok etish uchun Uzum Nasiya tizimi orqali mahsulotlarni muddatli to\'lov asosida xarid qilish va oylik to‘lovlarni o‘z vaqtida amalga oshirish kerak. Bo\'lajak o\'yinlar sanasi va joylarini ijtimoiy tarmoqlarimizda oldindan e\'lon qilamiz',
      text_ru: 'Очередная грандиозная акция от Uzum Nasiya!\n ' + '<br>' +
        '\n' + 'На этот раз розыгрыш прошел 26 февраля в магазине бытовой техники и электроники “MEDIAPARK Bozori” Караташ. Участники розыгрыша стали обладателями холодильника, телевизора, стиральной машины и многих других ценных призов. \n' +
        '\n' + 'В нашей ежемесячной акции может победить каждый, а начиная с июня раз в два месяца будут разыгрываться по одному автомобилю Chevrolet Spark. Чтобы принять участие в розыгрыше Вам нужно приобрести товары в рассрочку через систему Uzum Nasiya и вовремя вносить ежемесячный платёж. О датах и местах будущих розыгрышей мы будем объявлять заранее в наших социальных сетях.',
    },
  ],
};