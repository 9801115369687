// let today = new Date();
// let currentDate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

import meidauz from '../assets/img/chnaged.jpg';
import meidaru from '../assets/img/mediaru.png';
import riveruz from '../assets/img/reiverrauz.jpg';
import riverru from '../assets/img/riverraru.jpg';
import ImgCard from '../assets/img/second-image-desktop.png';
import ImgMobile from '../assets/img/card-second-mobile.png';
// import first from "../assets/img/first.png"
// import second from "../assets/img/second.png"
// import third from "../assets/img/third.png"
// import four from "../assets/img/fourth.png"
// import fife from "../assets/img/fifth.png"
// import six from "../assets/img/sixth.png"
// import seven from "../assets/img/seventh.png"
// import eight from "../assets/img/eighth.png"

// riveria
import onet from '../assets/img/riveria/ENZ_5366.jpg';
import twot from '../assets/img/riveria/ENZ_5386.jpg';
import threet from '../assets/img/riveria/ENZ_5392.jpg';
import fourt from '../assets/img/riveria/ENZ_5399.jpg';
import fift from '../assets/img/riveria/ENZ_5409.jpg';
import sixt from '../assets/img/riveria/ENZ_5413.jpg';
import sevent from '../assets/img/riveria/ENZ_5055.jpg';
import eight from '../assets/img/riveria/ENZ_5043.jpg';
import ninet from '../assets/img/riveria/ENZ_5040.jpg';
import tent from '../assets/img/riveria/ENZ_5366.jpg';
import elevent from '../assets/img/riveria/ENZ_5028.jpg';
import twelvet from '../assets/img/riveria/1-1224.jpg';
import thirteent from '../assets/img/riveria/1-1296.jpg';
import fourteent from '../assets/img/riveria/1-1317.jpg';
import fifteenT from '../assets/img/riveria/1-1411.jpg';
import sixteenT from '../assets/img/riveria/ENZ_5059.jpg';
import seventeent from '../assets/img/riveria/ENZ_5274.jpg';
import eightteent from '../assets/img/riveria/ENZ_5308.jpg';
import nineteent from '../assets/img/riveria/ENZ_5331.jpg';
import twenteent from '../assets/img/riveria/ENZ_5336.jpg';
import twentyonet from '../assets/img/riveria/ENZ_5344.jpg';
import twentytwot from '../assets/img/riveria/ENZ_5360.jpg';
import twenttwot from '../assets/img/riveria/ENZ_5391.jpg';
import twentythreedt from '../assets/img/riveria/ENZ_5396.jpg';
import twentyfift from '../assets/img/riveria/ENZ_5406.jpg';


// media
import mone from '../assets/img/media/DSC_2159.jpg';
import msecond from '../assets/img/media/DSC_1788.jpg';
import mthird from '../assets/img/media/DSC_1763.jpg';
import mfifth from '../assets/img/media/DSC_1759.jpg';
import msix from '../assets/img/media/DSC_1750.jpg';
import meight from '../assets/img/media/DSC_1736.jpg';
import mnine from '../assets/img/media/DSC_1734.jpg';
import mten from '../assets/img/media/DSC_1726.jpg';
import meleven from '../assets/img/media/DSC_1719.jpg';
import mtwelve from '../assets/img/media/DSC_1716.jpg';
import mthirteen from '../assets/img/media/DSC_1712.jpg';
import mfourteen from '../assets/img/media/DSC_1703.jpg';
import mfifteen from '../assets/img/media/DSC_1692.jpg';
import msixteen from '../assets/img/media/DSC_1996.jpg';
import mseventeen from '../assets/img/media/DSC_1685.jpg';
import mtwenty from '../assets/img/media/DSC_1687.jpg';
import mtwentyone from '../assets/img/media/DSC_1789.jpg';
import mtwentytwo from '../assets/img/media/DSC_1808.jpg';
import mtwentythree from '../assets/img/media/DSC_1819.jpg';
import mtwentytwofour from '../assets/img/media/DSC_1822.jpg';
import mtwentyfifth from '../assets/img/media/DSC_1858.jpg';
import mtwentysix from '../assets/img/media/DSC_1925.jpg';
import mtwentyeight from '../assets/img/media/DSC_1929.jpg';
import mtwentynine from '../assets/img/media/DSC_1929.jpg';
import mthirty from '../assets/img/media/DSC_1935.jpg';
import mthirtyone from '../assets/img/media/DSC_1936.jpg';
import mthirtytwo from '../assets/img/media/DSC_1941.jpg';
import mthirtythree from '../assets/img/media/DSC_1943.jpg';
import meighteen from '../assets/img/media/DSC_1944.jpg';


import i18n from '../i18n/i18n';
import localize from '../filters/localize.filter';

import text from '../data/texts';

// Qaratash Mediapark 26.02.2022
import qaratash_one from '../assets/img/promotions/26.02.2022-qoratosh/1.jpg';
import qaratash_two from '../assets/img/promotions/26.02.2022-qoratosh/2.jpg';
import qaratash_three from '../assets/img/promotions/26.02.2022-qoratosh/3.jpg';
import qaratash_four from '../assets/img/promotions/26.02.2022-qoratosh/4.jpg';
import qaratash_five from '../assets/img/promotions/26.02.2022-qoratosh/5.jpg';
import qaratash_six from '../assets/img/promotions/26.02.2022-qoratosh/6.jpg';
import qaratash_seven from '../assets/img/promotions/26.02.2022-qoratosh/7.jpg';
import qaratash_eight from '../assets/img/promotions/26.02.2022-qoratosh/8.jpg';
import qaratash_nine from '../assets/img/promotions/26.02.2022-qoratosh/9.jpg';
import qaratash_ten from '../assets/img/promotions/26.02.2022-qoratosh/10.jpg';
import qaratash_eleven from '../assets/img/promotions/26.02.2022-qoratosh/11.jpg';
import qaratash_twelve from '../assets/img/promotions/26.02.2022-qoratosh/12.jpg';
import qaratash_thirteen from '../assets/img/promotions/26.02.2022-qoratosh/13.jpg';
import qaratash_fourteen from '../assets/img/promotions/26.02.2022-qoratosh/14.jpg';
import qaratash_fifteen from '../assets/img/promotions/26.02.2022-qoratosh/15.jpg';
import qaratash_sixteen from '../assets/img/promotions/26.02.2022-qoratosh/16.jpg';
import qaratash_seventeen from '../assets/img/promotions/26.02.2022-qoratosh/17.jpg';
import qaratash_eighteen from '../assets/img/promotions/26.02.2022-qoratosh/18.jpg';
import qaratash_banner_uz from '../assets/img/promotions/26.02.2022-qoratosh/banner_uz.jpg';
import qaratash_banner_ru from '../assets/img/promotions/26.02.2022-qoratosh/banner_ru.jpg';


const Riviera = {
  date: '26.11.2021',
};

const MediaPark = {
  date: '26.12.2021',
};

const MediaPark_Qaratash = {
  date: '26.02.2022',
};

const obj = {
  news: [
    {
      id: 1,
      locale: {
        slug: '',
        image: {
          preview: ImgCard,
        },
        imageMobile: {
          preview: ImgMobile,
        },
        title: 'Arena Markaz (Depo Mall)',
        preview_text: i18n.locale === 'ru' ? text.texts[0].text_ru : text.texts[0].text_uz,
      },
      data: '26.03.2022',
      upcoming: true,
    },

    // {
    //     id: 2,
    //     locale: {
    //         slug: '',
    //         image: {
    //             preview: ImgCard
    //         },
    //         title: 'Lorem ipsum dolor sit amet, consectetur!',
    //         preview_text: 'Some quick example text to build on the card title and make up the',
    //     },
    //     data: currentDate,
    //     upcoming: true,
    // },
  ],

  lastNews: [
    {
      id: 1,
      locale: {
        slug: '',
        image: {
          preview: i18n.locale === 'ru' ? riverru : riveruz,
        },
        // image_uz: {
        //     preview: riveruz,
        // },
        // image_ru: {
        //     preview: riverru,
        //
        // },
        title: 'Riviera mall',
        secondTitle: i18n.locale === 'ru' ? 'Прошедшие розыгрыши' : 'O\'tgan o\'yinlar',
        date: Riviera.date,
        preview_text: i18n.locale === 'ru' ? text.texts[1].text_ru : text.texts[1].text_uz,
      },
      data: Riviera.date,
      upcoming: false,
      image: [
        {
          type: 'image',
          src: onet,
        },
        {
          type: 'image',
          src: twot,
        },
        {
          type: 'image',
          src: threet,
        },
        {
          type: 'image',
          src: fourt,
        },
        {
          type: 'image',
          src: fift,
        },
        {
          type: 'image',
          src: sixt,
        },
        {
          type: 'image',
          src: sevent,
        },
        {
          type: 'image',
          src: eight,
        },
        {
          type: 'image',
          src: ninet,
        },
        {
          type: 'image',
          src: tent,
        },
        {
          type: 'image',
          src: elevent,
        },
        {
          type: 'image',
          src: twelvet,
        },
        {
          type: 'image',
          src: thirteent,
        },
        {
          type: 'image',
          src: fourteent,
        },
        {
          type: 'image',
          src: fifteenT,
        },
        {
          type: 'image',
          src: sixteenT,
        },
        {
          type: 'image',
          src: seventeent,
        },
        {
          type: 'image',
          src: eightteent,
        },
        {
          type: 'image',
          src: nineteent,
        },
        {
          type: 'image',
          src: twenteent,
        },
        {
          type: 'image',
          src: twenttwot,
        },
        {
          type: 'image',
          src: twentythreedt,
        },
        {
          type: 'image',
          src: twentyfift,
        },
        {
          type: 'image',
          src: twenttwot,
        },
        {
          type: 'image',
          src: twentyonet,
        },
        {
          type: 'image',
          src: twentytwot,
        },
      ],
      winners: localize('akciya')['winners']['riviera'],
      status: true,
    },
    {
      id: 2,
      locale: {
        slug: '',
        image: {
          preview: i18n.locale === 'ru' ? meidaru : meidauz,
        },
        // image_uz: {
        //     preview: this.textuz2,
        // },
        // image_ru: {
        //     preview: this.textru2,
        // },
        title: 'MediaPark',
        secondTitle: i18n.locale === 'ru' ? 'Прошедшие розыгрыши' : 'O\'tgan o\'yinlar',
        date: MediaPark.date,
        preview_text: i18n.locale === 'ru' ? text.texts[2].text_ru : text.texts[2].text_uz,
      },
      data: MediaPark.date,
      upcoming: false,
      image: [
        {
          type: 'image',
          src: msecond,
        },
        // {
        //   type: 'video',
        //   src: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4',
        //   poster: 'https://picsum.photos/id/6/280/171'
        // },
        {
          type: 'image',
          src: msix,
        },
        {
          type: 'image',
          src: meight,
        },
        {
          type: 'image',
          src: mnine,
        },
        {
          type: 'image',
          src: mten,
        },
        // {
        //     type: 'image',
        //     src: sixt,
        // },
        {
          type: 'image',
          src: meleven,
        },
        {
          type: 'image',
          src: mtwelve,
        },
        {
          type: 'image',
          src: mthirteen,
        },
        {
          type: 'image',
          src: mfifth,
        },
        {
          type: 'image',
          src: msixteen,
        },
        {
          type: 'image',
          src: mseventeen,
        },
        {
          type: 'image',
          src: meighteen,
        },
        {
          type: 'image',
          src: mtwenty,
        },
        {
          type: 'image',
          src: mtwentyone,
        },
        {
          type: 'image',
          src: mfourteen,
        },
        {
          type: 'image',
          src: mfifteen,
        },
        {
          type: 'image',
          src: mtwentytwo,
        },
        {
          type: 'image',
          src: mtwentythree,
        },
        {
          type: 'image',
          src: mtwentyfifth,
        },
        {
          type: 'image',
          src: mtwentytwofour,
        },
        {
          type: 'image',
          src: mtwentysix,
        },
        {
          type: 'image',
          src: meighteen,
        },
        {
          type: 'image',
          src: mtwentyeight,
        },
        {
          type: 'image',
          src: mtwentynine,
        },
        {
          type: 'image',
          src: mthirty,
        },
        {
          type: 'image',
          src: mthirtyone,
        },
        {
          type: 'image',
          src: mthirtytwo,
        },
        {
          type: 'image',
          src: mthirtytwo,
        },
        {
          type: 'image',
          src: mthirtythree,
        },
      ],
      winners: localize('akciya')['winners']['mediapark'],
      status: true,
    },
    {
      id: 3,
      locale: {
        slug: '',
        image: {
          preview: i18n.locale === 'ru' ? qaratash_banner_ru : qaratash_banner_uz,
        },
        title: i18n.locale === 'ru' ? 'MediaPark Караташ' : 'MediaPark Qoratosh',
        secondTitle: i18n.locale === 'ru' ? 'Прошедшие розыгрыши' : 'O\'tgan o\'yinlar',
        date: MediaPark_Qaratash.date,
        preview_text: i18n.locale === 'ru' ? text.texts[3].text_ru : text.texts[3].text_uz,
      },
      data: MediaPark_Qaratash.date,
      upcoming: false,
      image: [
        {
          type: 'image',
          src: qaratash_one,
        },
        // {
        //   type: 'video',
        //   src: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4',
        //   poster: 'https://picsum.photos/id/6/280/171'
        // },
        {
          type: 'image',
          src: qaratash_two,
        },
        {
          type: 'image',
          src: qaratash_three,
        },
        {
          type: 'image',
          src: qaratash_four,
        },
        {
          type: 'image',
          src: qaratash_five,
        },
        {
          type: 'image',
          src: qaratash_six,
        },
        {
          type: 'image',
          src: qaratash_seven,
        },
        {
          type: 'image',
          src: qaratash_eight,
        },
        {
          type: 'image',
          src: qaratash_nine,
        },
        {
          type: 'image',
          src: qaratash_ten,
        },
        {
          type: 'image',
          src: qaratash_eleven,
        },
        {
          type: 'image',
          src: qaratash_twelve,
        },
        {
          type: 'image',
          src: qaratash_thirteen,
        },
        {
          type: 'image',
          src: qaratash_fourteen,
        },
        {
          type: 'image',
          src: qaratash_fifteen,
        },
        {
          type: 'image',
          src: qaratash_sixteen,
        },
        {
          type: 'image',
          src: qaratash_seventeen,
        },
        {
          type: 'image',
          src: qaratash_eighteen,
        },
      ],
      winners: localize('akciya')['winners']['mediapark_qoratosh'],
      status: true,
    },


    {
      id: 5,
      locale: {
        slug: '',
        image: {
          preview: i18n.locale === 'ru' ? riverru : riveruz,
        },
        // image_uz: {
        //     preview: riveruz,
        // },
        // image_ru: {
        //     preview: riverru,
        //
        // },
        title: 'Riviera mall',
        secondTitle: i18n.locale === 'ru' ? 'Прошедшие розыгрыши' : 'O\'tgan o\'yinlar',
        date: Riviera.date,
        preview_text: i18n.locale === 'ru' ? text.texts[0].text_ru : text.texts[0].text_uz,
      },
      data: Riviera.date,
      upcoming: false,
      image: [
        {
          type: 'image',
          src: onet,
        },
        {
          type: 'image',
          src: twot,
        },
        {
          type: 'image',
          src: threet,
        },
        {
          type: 'image',
          src: fourt,
        },
        {
          type: 'image',
          src: fift,
        },
        {
          type: 'image',
          src: sixt,
        },
        {
          type: 'image',
          src: sevent,
        },
        {
          type: 'image',
          src: eight,
        },
        {
          type: 'image',
          src: ninet,
        },
        {
          type: 'image',
          src: tent,
        },
        {
          type: 'image',
          src: elevent,
        },
        {
          type: 'image',
          src: twelvet,
        },
        {
          type: 'image',
          src: thirteent,
        },
        {
          type: 'image',
          src: fourteent,
        },
        {
          type: 'image',
          src: fifteenT,
        },
        {
          type: 'image',
          src: sixteenT,
        },
        {
          type: 'image',
          src: seventeent,
        },
        {
          type: 'image',
          src: eightteent,
        },
        {
          type: 'image',
          src: nineteent,
        },
        {
          type: 'image',
          src: twenteent,
        },
        {
          type: 'image',
          src: twenttwot,
        },
        {
          type: 'image',
          src: twentythreedt,
        },
        {
          type: 'image',
          src: twentyfift,
        },
        {
          type: 'image',
          src: twenttwot,
        },
        {
          type: 'image',
          src: twentyonet,
        },
        {
          type: 'image',
          src: twentytwot,
        },
      ],
      winners: localize('akciya')['winners']['riviera'],
      status: false,
    },
    {
      id: 6,
      locale: {
        slug: '',
        image: {
          preview: i18n.locale === 'ru' ? meidaru : meidauz,
        },
        // image_uz: {
        //     preview: this.textuz2,
        // },
        // image_ru: {
        //     preview: this.textru2,
        // },
        title: 'MediaPark',
        secondTitle: i18n.locale === 'ru' ? 'Прошедшие розыгрыши' : 'O\'tgan o\'yinlar',
        date: MediaPark.date,
        preview_text: i18n.locale === 'ru' ? text.texts[1].text_ru : text.texts[1].text_uz,
      },
      data: MediaPark.date,
      upcoming: false,
      image: [
        {
          type: 'image',
          src: msecond,
        },
        // {
        //   type: 'video',
        //   src: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4',
        //   poster: 'https://picsum.photos/id/6/280/171'
        // },
        {
          type: 'image',
          src: msix,
        },
        {
          type: 'image',
          src: meight,
        },
        {
          type: 'image',
          src: mnine,
        },
        {
          type: 'image',
          src: mten,
        },
        // {
        //     type: 'image',
        //     src: sixt,
        // },
        {
          type: 'image',
          src: meleven,
        },
        {
          type: 'image',
          src: mtwelve,
        },
        {
          type: 'image',
          src: mthirteen,
        },
        {
          type: 'image',
          src: mfifth,
        },
        {
          type: 'image',
          src: msixteen,
        },
        {
          type: 'image',
          src: mseventeen,
        },
        {
          type: 'image',
          src: meighteen,
        },
        {
          type: 'image',
          src: mtwenty,
        },
        {
          type: 'image',
          src: mtwentyone,
        },
        {
          type: 'image',
          src: mfourteen,
        },
        {
          type: 'image',
          src: mfifteen,
        },
        {
          type: 'image',
          src: mtwentytwo,
        },
        {
          type: 'image',
          src: mtwentythree,
        },
        {
          type: 'image',
          src: mtwentyfifth,
        },
        {
          type: 'image',
          src: mtwentytwofour,
        },
        {
          type: 'image',
          src: mtwentysix,
        },
        {
          type: 'image',
          src: meighteen,
        },
        {
          type: 'image',
          src: mtwentyeight,
        },
        {
          type: 'image',
          src: mtwentynine,
        },
        {
          type: 'image',
          src: mthirty,
        },
        {
          type: 'image',
          src: mthirtyone,
        },
        {
          type: 'image',
          src: mthirtytwo,
        },
        {
          type: 'image',
          src: mthirtytwo,
        },
        {
          type: 'image',
          src: mthirtythree,
        },
      ],
      winners: localize('akciya')['winners']['mediapark'],
      status: false,
    },
    {
      id: 7,
      locale: {
        slug: '',
        image: {
          preview: i18n.locale === 'ru' ? riverru : riveruz,
        },
        // image_uz: {
        //     preview: riveruz,
        // },
        // image_ru: {
        //     preview: riverru,
        //
        // },
        title: 'Riviera mall',
        secondTitle: i18n.locale === 'ru' ? 'Прошедшие розыгрыши' : 'O\'tgan o\'yinlar',
        date: Riviera.date,
        preview_text: i18n.locale === 'ru' ? text.texts[0].text_ru : text.texts[0].text_uz,
      },
      data: Riviera.date,
      upcoming: false,
      image: [
        {
          type: 'image',
          src: onet,
        },
        {
          type: 'image',
          src: twot,
        },
        {
          type: 'image',
          src: threet,
        },
        {
          type: 'image',
          src: fourt,
        },
        {
          type: 'image',
          src: fift,
        },
        {
          type: 'image',
          src: sixt,
        },
        {
          type: 'image',
          src: sevent,
        },
        {
          type: 'image',
          src: eight,
        },
        {
          type: 'image',
          src: ninet,
        },
        {
          type: 'image',
          src: tent,
        },
        {
          type: 'image',
          src: elevent,
        },
        {
          type: 'image',
          src: twelvet,
        },
        {
          type: 'image',
          src: thirteent,
        },
        {
          type: 'image',
          src: fourteent,
        },
        {
          type: 'image',
          src: fifteenT,
        },
        {
          type: 'image',
          src: sixteenT,
        },
        {
          type: 'image',
          src: seventeent,
        },
        {
          type: 'image',
          src: eightteent,
        },
        {
          type: 'image',
          src: nineteent,
        },
        {
          type: 'image',
          src: twenteent,
        },
        {
          type: 'image',
          src: twenttwot,
        },
        {
          type: 'image',
          src: twentythreedt,
        },
        {
          type: 'image',
          src: twentyfift,
        },
        {
          type: 'image',
          src: twenttwot,
        },
        {
          type: 'image',
          src: twentyonet,
        },
        {
          type: 'image',
          src: twentytwot,
        },
      ],
      winners: localize('akciya')['winners']['riviera'],
    },
    {
      id: 8,
      locale: {
        slug: '',
        image: {
          preview: i18n.locale === 'ru' ? meidaru : meidauz,
        },
        // image_uz: {
        //     preview: this.textuz2,
        // },
        // image_ru: {
        //     preview: this.textru2,
        // },
        title: 'MediaPark',
        secondTitle: i18n.locale === 'ru' ? 'Прошедшие розыгрыши' : 'O\'tgan o\'yinlar',
        date: MediaPark.date,
        preview_text: i18n.locale === 'ru' ? text.texts[1].text_ru : text.texts[1].text_uz,
      },
      data: MediaPark.date,
      upcoming: false,
      image: [
        {
          type: 'image',
          src: msecond,
        },
        // {
        //   type: 'video',
        //   src: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4',
        //   poster: 'https://picsum.photos/id/6/280/171'
        // },
        {
          type: 'image',
          src: msix,
        },
        {
          type: 'image',
          src: meight,
        },
        {
          type: 'image',
          src: mnine,
        },
        {
          type: 'image',
          src: mten,
        },
        // {
        //     type: 'image',
        //     src: sixt,
        // },
        {
          type: 'image',
          src: meleven,
        },
        {
          type: 'image',
          src: mtwelve,
        },
        {
          type: 'image',
          src: mthirteen,
        },
        {
          type: 'image',
          src: mfifth,
        },
        {
          type: 'image',
          src: msixteen,
        },
        {
          type: 'image',
          src: mseventeen,
        },
        {
          type: 'image',
          src: meighteen,
        },
        {
          type: 'image',
          src: mtwenty,
        },
        {
          type: 'image',
          src: mtwentyone,
        },
        {
          type: 'image',
          src: mfourteen,
        },
        {
          type: 'image',
          src: mfifteen,
        },
        {
          type: 'image',
          src: mtwentytwo,
        },
        {
          type: 'image',
          src: mtwentythree,
        },
        {
          type: 'image',
          src: mtwentyfifth,
        },
        {
          type: 'image',
          src: mtwentytwofour,
        },
        {
          type: 'image',
          src: mtwentysix,
        },
        {
          type: 'image',
          src: meighteen,
        },
        {
          type: 'image',
          src: mtwentyeight,
        },
        {
          type: 'image',
          src: mtwentynine,
        },
        {
          type: 'image',
          src: mthirty,
        },
        {
          type: 'image',
          src: mthirtyone,
        },
        {
          type: 'image',
          src: mthirtytwo,
        },
        {
          type: 'image',
          src: mthirtytwo,
        },
        {
          type: 'image',
          src: mthirtythree,
        },
      ],
      winners: localize('akciya')['winners']['mediapark'],
    },
    {
      id: 9,
      locale: {
        slug: '',
        image: {
          preview: i18n.locale === 'ru' ? riverru : riveruz,
        },
        // image_uz: {
        //     preview: riveruz,
        // },
        // image_ru: {
        //     preview: riverru,
        //
        // },
        title: 'Riviera mall',
        secondTitle: i18n.locale === 'ru' ? 'Прошедшие розыгрыши' : 'O\'tgan o\'yinlar',
        date: Riviera.date,
        preview_text: i18n.locale === 'ru' ? text.texts[0].text_ru : text.texts[0].text_uz,
      },
      data: Riviera.date,
      upcoming: false,
      image: [
        {
          type: 'image',
          src: onet,
        },
        {
          type: 'image',
          src: twot,
        },
        {
          type: 'image',
          src: threet,
        },
        {
          type: 'image',
          src: fourt,
        },
        {
          type: 'image',
          src: fift,
        },
        {
          type: 'image',
          src: sixt,
        },
        {
          type: 'image',
          src: sevent,
        },
        {
          type: 'image',
          src: eight,
        },
        {
          type: 'image',
          src: ninet,
        },
        {
          type: 'image',
          src: tent,
        },
        {
          type: 'image',
          src: elevent,
        },
        {
          type: 'image',
          src: twelvet,
        },
        {
          type: 'image',
          src: thirteent,
        },
        {
          type: 'image',
          src: fourteent,
        },
        {
          type: 'image',
          src: fifteenT,
        },
        {
          type: 'image',
          src: sixteenT,
        },
        {
          type: 'image',
          src: seventeent,
        },
        {
          type: 'image',
          src: eightteent,
        },
        {
          type: 'image',
          src: nineteent,
        },
        {
          type: 'image',
          src: twenteent,
        },
        {
          type: 'image',
          src: twenttwot,
        },
        {
          type: 'image',
          src: twentythreedt,
        },
        {
          type: 'image',
          src: twentyfift,
        },
        {
          type: 'image',
          src: twenttwot,
        },
        {
          type: 'image',
          src: twentyonet,
        },
        {
          type: 'image',
          src: twentytwot,
        },
      ],
      winners: localize('akciya')['winners']['riviera'],
    },
    {
      id: 10,
      locale: {
        slug: '',
        image: {
          preview: i18n.locale === 'ru' ? meidaru : meidauz,
        },
        // image_uz: {
        //     preview: this.textuz2,
        // },
        // image_ru: {
        //     preview: this.textru2,
        // },
        title: 'MediaPark',
        secondTitle: i18n.locale === 'ru' ? 'Прошедшие розыгрыши' : 'O\'tgan o\'yinlar',
        date: MediaPark.date,
        preview_text: i18n.locale === 'ru' ? text.texts[1].text_ru : text.texts[1].text_uz,
      },
      data: MediaPark.date,
      upcoming: false,
      image: [
        {
          type: 'image',
          src: msecond,
        },
        // {
        //   type: 'video',
        //   src: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4',
        //   poster: 'https://picsum.photos/id/6/280/171'
        // },
        {
          type: 'image',
          src: msix,
        },
        {
          type: 'image',
          src: meight,
        },
        {
          type: 'image',
          src: mnine,
        },
        {
          type: 'image',
          src: mten,
        },
        // {
        //     type: 'image',
        //     src: sixt,
        // },
        {
          type: 'image',
          src: meleven,
        },
        {
          type: 'image',
          src: mtwelve,
        },
        {
          type: 'image',
          src: mthirteen,
        },
        {
          type: 'image',
          src: mfifth,
        },
        {
          type: 'image',
          src: msixteen,
        },
        {
          type: 'image',
          src: mseventeen,
        },
        {
          type: 'image',
          src: meighteen,
        },
        {
          type: 'image',
          src: mtwenty,
        },
        {
          type: 'image',
          src: mtwentyone,
        },
        {
          type: 'image',
          src: mfourteen,
        },
        {
          type: 'image',
          src: mfifteen,
        },
        {
          type: 'image',
          src: mtwentytwo,
        },
        {
          type: 'image',
          src: mtwentythree,
        },
        {
          type: 'image',
          src: mtwentyfifth,
        },
        {
          type: 'image',
          src: mtwentytwofour,
        },
        {
          type: 'image',
          src: mtwentysix,
        },
        {
          type: 'image',
          src: meighteen,
        },
        {
          type: 'image',
          src: mtwentyeight,
        },
        {
          type: 'image',
          src: mtwentynine,
        },
        {
          type: 'image',
          src: mthirty,
        },
        {
          type: 'image',
          src: mthirtyone,
        },
        {
          type: 'image',
          src: mthirtytwo,
        },
        {
          type: 'image',
          src: mthirtytwo,
        },
        {
          type: 'image',
          src: mthirtythree,
        },
      ],
      winners: localize('akciya')['winners']['mediapark'],
    },

    // {
    //   id: 5,
    //   locale: {
    //     slug: '',
    //     image: {
    //       preview: ImgCard
    //     },
    //     preview_text: 'Some quick example text to build on the card title and make up the',
    //     title: 'Lorem ipsum dolor sit amet, consectetur!',
    //   },
    //   data: currentDate,
    //   upcoming: false,
    // },
    // {
    //   id: 6,
    //   locale: {
    //     slug: '',
    //     image: {
    //       preview: ImgCard
    //     },
    //     preview_text: 'Some quick example text to build on the card title and make up the',
    //     title: 'Lorem ipsum dolor sit amet, consectetur!',
    //   },
    //   data: currentDate,
    //   upcoming: false,
    // },
    // {
    //   id: 7,
    //   locale: {
    //     slug: '',
    //     image: {
    //       preview: ImgCard
    //     },
    //     preview_text: 'Some quick example text to build on the card title and make up the',
    //     title: 'Lorem ipsum dolor sit amet, consectetur!',
    //   },
    //   data: currentDate,
    //   upcoming: false,
    // },
  ],

  newsCardPromotions: [
    {
      id: 1,
      image: {
        preview: onet,
      },
      title: 'Riviera',
      date: Riviera.date,
      number: 21,

    },
    {
      id: 2,
      image: {
        preview: mone,
      },
      title: 'Media Park',
      date: MediaPark.date,
      number: 22,
    },
    {
      id: 3,
      image: {
        preview: threet,
      },
      title: 'Riviera',
      date: Riviera.date,
      number: 21,
    },
    {
      id: 4,
      image: {
        preview: mthird,
      },
      title: 'Media Park',
      date: MediaPark.date,
      number: 22,
    },
    {
      id: 5,
      image: {
        preview: fourt,
      },
      title: 'Riviera',
      date: Riviera.date,
      number: 21,
    },
    {
      id: 6,
      image: {
        preview: mfifth,
      },
      title: 'Media Park',
      date: MediaPark.date,
      number: 22,
    },
    {
      id: 7,
      image: {
        preview: sevent,
      },
      title: 'Riviera',
      date: Riviera.date,
      number: 21,
    },
    {
      id: 8,
      image: {
        preview: msixteen,
      },
      title: 'Media Park',
      date: MediaPark.date,
      number: 22,
    },

  ],
};

obj.lastNews.reverse();

export default obj;